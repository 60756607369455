import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { Layout, Menu, Row, Col, Dropdown } from 'antd';
import { MenuOutlined, UserOutlined, BellFilled, BellOutlined } from '@ant-design/icons';
import { useAuth } from './hooks/auth';
import { useOnPress } from './hooks/onButtonPress';
import { mainMenuItems, getAdminMenuItems, routeItems } from './navigations'
import Login from './pages/login/Login';
import type { MenuProps } from 'antd';
import { getNoticeCount } from './services/adminApi';
import MenuItem from './components/menu/MenuItem';

const { Header, Content, Footer } = Layout;

const content = [24, 22, 20, 18, 18];
const margin = [0, 1, 2, 3, 3];

const App: React.FC = () => {
  const { isAdmin, user } = useAuth();
  const adminMenuItems = getAdminMenuItems(user.role, user.isAdmin);
  const { onKeyPressed } = useOnPress();
  const [isAdminMode, setIsAdminMode] = useState(adminMenuItems.map(x => x.to).includes(window.location.pathname));
  const [noticesCount, setNoticesCount] = useState(0);

  useEffect(() => {
    if (!isAdmin) setIsAdminMode(false);
    if (user?.role === 3) getNoticeCount().then(setNoticesCount)
  }, [isAdmin])

  const adminClick = (e: any) => {
    if (e.ctrlKey) return;
    setIsAdminMode(!isAdminMode);
  }

  const items: MenuProps['items'] = [
    !noticesCount ? {
      key: '1',
      label: "Уведомления отсутствуют",
    } : null,
    noticesCount ? {
      key: '1',
      label: <a href='/admin/materials'>Требуется согласование <br /> <b>{noticesCount} несогласованных вознагражений</b></a>,
    } : null,
  ]

  const MainMenu = () => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ?
      <Row style={{ width: '100%' }}>
        <Col span={16}>
          <Menu className='overflow-left' overflowedIndicator={<MenuOutlined style={{ color: 'white' }} />} theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ backgroundColor: '#A40207' }}>
            {(isAdmin && isAdminMode ? adminMenuItems : mainMenuItems).map((item, key) => (
              <MenuItem key={key} to={item.to} label={item.label} />
            ))}
          </Menu>
        </Col>
        <Col span={8}>
          <Menu overflowedIndicator={<UserOutlined style={{ fontSize: '16px', color: 'white' }} />} theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ backgroundColor: '#A40207', display: 'flex', justifyContent: 'end', maxHeight: 64 }}>
            {!!isAdmin && <Menu.Item className='menu-item'>
              <Dropdown menu={{ items }} placement="bottom">
                {noticesCount ?
                  <BellFilled className="menu-link" style={{ fontSize: 19, position: 'relative', top: 3 }} /> :
                  <BellOutlined className="menu-link" style={{ fontSize: 19, position: 'relative', top: 3 }} />
                }
              </Dropdown>
              {!!noticesCount && <div style={{ textAlign: 'center', position: 'absolute', height: 15, width: 15, top: 18, left: 27, background: 'white', borderRadius: 15, color: '#a40207', lineHeight: '15px', }}>{noticesCount}</div>}
            </Menu.Item>}
            {!!(isAdmin && adminMenuItems.length) && <Menu.Item className='menu-item'><Link to={isAdminMode ? '/learning/categories' : adminMenuItems?.[0].to} className="menu-link" onClick={adminClick}> {isAdminMode ? 'Я пользователь' : 'Я админ'}</Link></Menu.Item>}
            <MenuItem to={`https://liderprodag.ru/${user?.role>0?'admin':''}`} label={'Лидер Продаж'} />
            <MenuItem to={'/profile'} label={<span><UserOutlined style={{ fontSize: '15px' }} /> Профиль</span>} />
          </Menu>
        </Col>
      </Row>
      :
      <></>
  };
  return (
    <BrowserRouter>
      <Layout className="layout" style={{ minHeight: '100vh', outline: 'none' }} onKeyDown={onKeyPressed} tabIndex={0} autoFocus>
        <Row style={{ backgroundColor: '#A40207' }}>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
          <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
            <Header style={{ backgroundColor: '#A40207' }}>
              <MainMenu />
            </Header>
          </Col>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
        </Row>
        <Row style={{ flex: '1 0 auto', padding: '0 20px' }}>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
          <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
            <Content>
              <div className="site-layout-content">
                <Routes>
                  {!user?.id ? <Route path={'/login'} element={<Login />} /> :
                    routeItems(user).map((item:any) => (
                      <Route key={item.path} path={item.path} element={item.element} />
                    ))}
                </Routes>
              </div>
            </Content>
          </Col>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
        </Row>
        <Row style={{ backgroundColor: '#A40207', display: 'flex', marginTop: '30px' }}>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
          <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
            <Footer style={{backgroundColor: '#A40207', color: '#fff'}}>
              <span>Copyright ©{new Date().getFullYear()}</span>
              <a style={{color: 'white'}} href="mailto:safin_ar@resanta.ru">Техподдержка: safin_ar@resanta.ru</a>
            </Footer>
          </Col>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
        </Row>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
